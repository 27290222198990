import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  Alert,
  ActivityIndicator,
  Switch,
  useWindowDimensions,
  Image,
  Dimensions,
} from "react-native";
import IconRefresh from "react-native-vector-icons/FontAwesome";
import IconEdit from "react-native-vector-icons/FontAwesome";
import * as ImagePicker from "expo-image-picker";

import SuccessPopup from "../../../components/SuccessPopup";

import DataTable from "react-data-table-component";
import IconLoc from "react-native-vector-icons/MaterialIcons";

import { RadioButton, Button } from "react-native-paper";
import moment from "moment";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";

import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { DatePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";

import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, baseUrlimage, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";
import { useSelector, useDispatch } from "react-redux";
import { projectHeader } from "../../../components/redux/actions";

export default function EditProjectProfile({ navigation, route }) {
  var dispatch = useDispatch();
  const { Projects_Id } = route?.params;
  // console.log("inside edit project id-", Projects_Id);

  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);
  const [ProjectNameData, setProjectNameData] = useState([]);
  const [filterData, setFilteredDataSource] = useState([]);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [Lat, setLatitude] = useState("");
  const [Long, setLongitude] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isOmegaLoading, setIsOmegaLoading] = useState(false);

  const [ProjectIdOmega, setProjectIdOmega] = useState("");

  const [ProjectID, setProjectID] = useState("");
  const [isProjectIDValid, setProjectIDValid] = useState(true);
  const [isProjectIDEntered, setProjectIDEntered] = useState(false);

  const [ProjectName, setProjectName] = useState("");
  const [isProjectNameValid, setProjectNameValid] = useState(true);
  const [isProjectNameEntered, setProjectNameEntered] = useState(false);

  const [ContractorName, setContractorName] = useState("");
  const [isContractorNameValid, setContractorNameValid] = useState(true);
  const [isContractorNameEntered, setContractorNameEntered] = useState(false);

  const [ClientName, setClientName] = useState("");
  const [isClientNameValid, setClientNameValid] = useState(true);
  const [isClientNameEntered, setClientNameEntered] = useState(false);

  const [ProjectDetails, setProjectDetails] = useState("");
  const [isProjectDetailsValid, setProjectDetailsValid] = useState(true);
  const [isProjectDetailsEntered, setProjectDetailsEntered] = useState(false);

  const [projectdeviceId, setProjectDeviceId] = useState(0);
  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegaDetails, setOmegaDetails] = useState([]);
  const [AnalogInput, setAnalogInput] = useState([]);
  const [DigitalInput, setDigitalInput] = useState([]);
  const [DataRecord, setDataRecord] = useState([]);
  const [userId, setUserId] = useState("");


  const [OmegavalueId, setOmegaValueiD] = useState("");
  const [shouldShow, setShouldShow] = useState(true);

  const [ProjectTypeData, setProjectTypeData] = useState([]);
  const [ProjectTypevalueId, setProjectTypeValueiD] = useState();


  //Dropdown value pickup
  const [OmegaIdValue, setOmegaIdValue] = useState(null);
  const [ProjectTypeValue, setProjectTypeValue] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [profileImageError, setProfileImageError] = useState("");

  //Error message
  const [ProjectIdError, setProjectIDError] = useState("");

  const [ProjectNameError, setProjectNameError] = useState("");
  const [ClientnameError, setClientnameError] = useState("");
  const [ProjectDetailsError, setProjectDetailsError] = useState("");
  const [ProjectTypeError, setProjectTypeError] = useState("");

  const [ContractorError, setContractorError] = useState("");

  const [pinError, setPinError] = useState("");
  const [khasaraError, setkhasaraError] = useState("");
  const [OmegaError, setOmegaError] = useState("");

  const [qualification_Error, setQualificationError] = useState("");
  //Dropdown
  const [isAssignchecked, setAssignChecked] = useState(1);
  const dropdownRefOmegaiD = useRef();
  const dropdownRefProjectType = useRef();

  const closeMenu = () => {
    dropdownRefOmegaiD.current.close();
    dropdownRefProjectType.current.close();
    //return calls;
    // // console.log("on scroll close in dropdown")
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  //DOb
  const [date, setDate] = React.useState < Date | undefined > (undefined);
  const [open, setOpen] = React.useState(false);
  const [dashboardName, setDashboardName] = useState([]);

  //DOJ
  const [joinDate, setJoinDate] = React.useState < Date | undefined > (undefined);
  const [joinOpen, setJoinOpen] = React.useState(false);

  //Regex
  var alpha_pattern = new RegExp(/^[a-zA-Z0-9 ]*$/);

  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_numeric = new RegExp(/^[a-zA-Z0-9]*$/);
  var alpha_numeric = new RegExp(/^[a-z0-9][a-z0-9 ]{1,48}[a-z0-9]$/i);

  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );
  // var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  // var number_regex = new RegExp(/^[0-9]*$/);
  const regex = /^[0-9]*$/;

  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const columns = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];
  const columnsAnalog = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];

  const columnDigitalInput = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Input number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Input Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];

  const getAllProjectData = async (sortdata: never[]) => {
    setIsLoading(true);
    if (sortdata.length > 0) {
      // setProjectDataLength(sortdata.length);
      setProjectNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var getProjectData = JSON.stringify({
          module: "project",
          relation: [],
        });
        const ProjectNameResponse = await postData(
          getProjectData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = ProjectNameResponse.code;
        if (code == 1) {


          // setProjectDataLength(ProjectNameResponse.data.length);
          setProjectNameData(ProjectNameResponse.data);
          setFilteredDataSource(ProjectNameResponse.data);
          // const results = jsonToCSV(ProjectNameResponse.data);
          // // console.log("csv==", results);
        } else {
          setIsLoading(false);
          //alert(ProjectNameResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };
  const DigitalOutputRoute = () => (

    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >


              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={OmegaDetails}
                  customStyles={customStyles}
                  pagination
                />
              </View>

            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View >

  );

  const AnalogInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>

                <DataTable
                  // title="Country"
                  columns={columnsAnalog}
                  data={AnalogInput}
                  customStyles={customStyles}
                  pagination
                />

              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
  const DigitalInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnDigitalInput}
                  data={DigitalInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const renderScene = SceneMap({
    DigitalOutput: DigitalOutputRoute,
    DigitalInput: DigitalInputRoute,
    AnalogInput: AnalogInputRoute,
  });
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "DigitalOutput", title: "Digital Output" },

    { key: "DigitalInput", title: "Digital Input" },
    { key: "AnalogInput", title: "Analog Input" },
  ]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();

      // getOmegaIdList();
      getProjectTypeList();
      getProjectProfileById();
      getAllProjectData([]);
      getOmegaDependentByProjectId();
      getDashboardNameDetails();
    }, [])
  );

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      setUserId(userDetailsresponse.id)
    }
  };

  const renderOmegaIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {item.id === OmegavalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderProjectTypeListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.type}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.type}</Text>}
        {item.id === ProjectTypevalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    // console.log("result of imggg====", result.uri);
    setProfileImage(result.uri);

    if (!result.cancelled) {
      setProfileImage(result.uri);
    }
  };

  const CheckEnteredData = async () => {
    setProjectNameEntered(true);

    setContractorNameEntered(true);
    setProjectDetailsEntered(true);
    setClientNameEntered(true);
    // console.log("check nenter data");

    //setOmegaId(OmegaIds);
    if (
      isProjectIDValid &&
      isProjectNameValid &&
      isContractorNameValid &&
      isClientNameValid &&
      isProjectDetailsValid
    ) {
      // console.log("success");
      updateProjectProfileData();
      setProfileImageError("");
      setProjectIDError("");
      // setProjectNameError("");
      setProjectTypeError("");
      setContractorError("");
      setClientnameError("");
      setProjectDetailsError("");
    } else {
      // console.log("error ELSE");
      if (ProjectIdError == "") {
        setProjectIDError("");
      }
      // if (ProjectName == "") {
      //     setProjectNameError("Please enter first name");
      // }
      //project type
      if (ProjectTypevalueId == "") {
        setProjectTypeError("Please select Project Type");
      }

      //Contractor name
      if (ContractorName == "") {
        setContractorError("Please enter Contractor name ");
      }

      //omega id
      // if (OmegavalueId == "") {
      //   setOmegaError("Please select Omega Type");
      // }

      //  //Pro id
      //  if (OmegavalueId == "") {
      //   setOmegaError("Please select Omega Type");
      // }

      //client name
      if (ClientName == "") {
        setClientnameError("Please select Client name");
      }

      //Project details
      if (ProjectDetails == "") {
        setProjectDetailsError("Please select Project details");
      }
    }
  };

  // project type Get Api
  const getProjectTypeList = async () => {
    setIsLoading(true);
    try {
      var ProjectTypeData = JSON.stringify({
        module: "project_type",
        relation: [],
      });
      const ProjectTypeList = await postData(
        ProjectTypeData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = ProjectTypeList.code;
      if (code == 1) {
        // console.log("project list api====>", ProjectTypeList.data);
        setProjectTypeData(ProjectTypeList.data);
      } else {
        setIsLoading(false);
        //alert(ProjectTypeList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //Get project profile  data by id
  const getProjectProfileById = async () => {
    setIsLoading(true);
    try {
      var ProfileData = JSON.stringify({
        module: "project",
        relation: [
          {
            module: "project_type",
          },
          {
            module: "project_devices",
            subModule: "devices_master",
          },
        ],
        id: Projects_Id,
      });
      // console.log("ProfileData id", ProfileData);

      const ProjectProfileDataById = await postData(
        ProfileData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      var code = ProjectProfileDataById.code;
      if (code == 1) {
        console.log("ProjectProfileDataById", ProjectProfileDataById);
        var profileUrl =
          baseUrlimage +
          "uploads/project_profile/" +
          ProjectProfileDataById.data[0].profile_pic;
        setProfileImage(profileUrl);

        setProjectID(ProjectProfileDataById.data[0].project_code);
        setProjectName(ProjectProfileDataById.data[0].project_name);
        if (ProjectProfileDataById.data[0].project_devices.length > 0) {
          setProjectDeviceId(
            ProjectProfileDataById.data[0].project_devices[0].id
          );
          if (ProjectProfileDataById.data[0].project_devices[0].devices_master !== null) {
            setOmegaIdValue(
              ProjectProfileDataById.data[0].project_devices[0].devices_master
                .device_name
            );
            setOmegaValueiD(
              ProjectProfileDataById.data[0].project_devices[0].devices_master.id
            );
          } else {
            const value = "None";
            setOmegaIdValue(value);
            setOmegaValueiD("0");
          }
        } else {
          const value = "None";
          setOmegaIdValue(value);
          setOmegaValueiD("0");
        }

        console.log("id", ProjectProfileDataById.data[0].project_type.id)
        console.log("type", ProjectProfileDataById.data[0].project_type.type)
        setProjectTypeValueiD(ProjectProfileDataById.data[0].project_type.id);
        setProjectTypeValue(ProjectProfileDataById.data[0].project_type.type);
        setContractorName(ProjectProfileDataById.data[0].contractor);
        setClientName(ProjectProfileDataById.data[0].client);
        setProjectDetails(ProjectProfileDataById.data[0].project_details);
        setLatitude(
          ProjectProfileDataById.data[0].project_devices[0].devices_master.lat
        );
        setLongitude(
          ProjectProfileDataById.data[0].project_devices[0].devices_master.long
        );
        getOmegaDetails(
          ProjectProfileDataById.data[0].project_devices[0].devices_master.id
        );
      } else {
        setIsLoading(false);
        //alert(ProjectProfileDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error-->", error);
    }
  };
  //Get Dependent by project id
  const getOmegaDependentByProjectId = async () => {
    // // console.log("inside getOmegaDependentByProjectId", project_id);
    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        column: "project_id",
        value: Projects_Id,
        module: "devices_master",
      });
      // console.log("payload for dependented", OmegaIdData);
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getDependentMasterList"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("Bermad dependented Omega list api====>", OmegaIdList.data);
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          omega_id: "",
          device_name: "None",
          lat: "",
          long: "",
        });
        setOmegaIdData(mergeData);
        // setOmegaIdData(OmegaIdList.data);
      } else {
        setIsLoading(false);
        //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //Get details when click on omega id
  const getOmegaDetails = async (deviceId) => {
    // console.log("deviceIddeviceIddeviceIddeviceId==", deviceId);

    setIsLoading(true);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      // console.log("deviceIddeviceIddeviceIddeviceId==OmegaDetails", OmegaDetails);

      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getOmegaDetails"
      );
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        // console.log("Omega details api====>", OmegaDetailsResponse.data);
        // setShouldShow(true);

        // console.log("deviceeeeee=====id===", deviceId);
        setDataRecord(OmegaDetailsResponse.data);
        setOmegaDetails(OmegaDetailsResponse.data[0].valves);
        setAnalogInput(OmegaDetailsResponse.data[0].analogInput);
        setDigitalInput(OmegaDetailsResponse.data[0].digitalInput);


      } else {
        setDataRecord(OmegaDetailsResponse.data);
        // console.log("data record==", OmegaDetailsResponse.data);

        setIsLoading(false);

        //alert(OmegaDetailsResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Get Omega  id from Bermad when click on refresh button
  const getOmegaIdList = async () => {
    setIsOmegaLoading(true);
    if (ProjectID == "") {
      setProjectIDError("Please enter Project Id");
    }
    if (ClientName == "") {
      setClientnameError("Please select Client name");
    }
    if (ContractorName == "") {
      setContractorError("Please enter Contractor name ");
    }
    if (ProjectTypevalueId == "") {
      setProjectTypeError("Please select Project Type");
    }
    try {
      var OmegaIdData = JSON.stringify({
        id: ProjectID,
        client: ClientName,
        contractor: ContractorName,
        project_type_id: ProjectTypevalueId,
      });
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getOmegaFromBermad"
      );
      setIsOmegaLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("Omega list api====>", OmegaIdList.data);
        setProjectName(OmegaIdList.data[0].project_name)
        setOmegaIdData(OmegaIdList.data);
      } else {
        setIsOmegaLoading(false);
        //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsOmegaLoading(false);
      // console.log(error);
    }
  };
  //Project Profile update Api
  const updateProjectProfileData = async () => {
    // console.log("projectdeviceId", projectdeviceId);
    setIsLoading(true);
    try {
      var ProjectMasterData = JSON.stringify({
        //module: "project",
        id: Projects_Id,
        // data: {
        //   profile_pic: profileImage,

        //   project_type_id: ProjectTypevalueId,
        //   project_name: ProjectName,
        //   client: ClientName,
        //   contractor: ContractorName,
        //   omega_id: OmegaIdValue,
        //   project_details: ProjectDetails,
        //   active: 1,
        //   based_on: isAssignchecked,
        // },
        // submodule: "project_devices",
        // submoduledata: [
        //   {
        //     id: projectdeviceId,
        //     data: {
        //       project_id: Projects_Id,
        //       device_id: OmegavalueId,
        //       active: 1,
        //     },
        //   },
        // ],
        modelName: "project",  //change api payload -rohitB
        inputData: {
        project_type_id: ProjectTypevalueId,
        project_name: ProjectName,
        client: ClientName,
        contractor: ContractorName,
        omega_id: OmegaIdValue,
        project_details: ProjectDetails,
        active: 1,
        },
        relation: {
        subModelName: "project_devices",
        subModelInputData: [
            {
                id: projectdeviceId,
                project_id: Projects_Id,
                device_id: OmegavalueId,
                active: 1,
            },
        ],
       },
      });

      // console.log("update Project payload===", ProjectMasterData);
      const FaarmerProfileCreateResponse = await postData(
        ProjectMasterData,
        "masters/profileCreationAndUpdation"
      );

      setIsLoading(false);

      var code = FaarmerProfileCreateResponse.code;
      if (code == 1) {
        if (tempArray.length == 0) {
          setPopupMessage("Record Updated Successfully");
          setissuccessVisible(true);
          setresponsestatus("success");
          dispatch(projectHeader());
          navigation.navigate("ProjectProfile");
        } else {
          updateProjectWiseDashboardList()
        }
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(FaarmerProfileCreateResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Get all dashboard names
  const getDashboardNameDetails = async () => {
    setIsLoading(true);
    try {
      fetch(baseUrl + "masters/getProjectWiseDashboardList/" + Projects_Id, {
        method: "GET"
      })
        .then((data) => {
          return data.json();
        })
        .catch((error) => {
          // console.log(error);
        })
        .then((response) => {
          console.log("dashboardNameDetailsResponse", response)
          if (response.code == 1) {
            setDashboardName(response.data)
          }
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateProjectWiseDashboardList = async () => {
    setIsLoading(true);
    try {
      var dashboardNameBody = JSON.stringify({
        dashboard_name_array: tempArray
      });
      console.log("dashboardNameBody===>", dashboardNameBody);
      console.log("Projects_Id===>", Projects_Id);
      console.log("userId===>", userId);
      const updateProjectWiseDashboardResponse = await postData(
        dashboardNameBody,
        "masters/updateProjectWiseDashboardList/" + Projects_Id + "/" + userId
      );

      setIsLoading(false);

      var code = updateProjectWiseDashboardResponse.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        dispatch(projectHeader());
        navigation.navigate("ProjectProfile");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const [tempArray, setTempArray] = useState([]);
  const customDashboardNameStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "25px", // override the row height
        // width: "800px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
        // width: "800px",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const columnsDashboardName = [
    {
      name: "Sr. No",
      selector: (row) => row.id,
      sortable: true
    },
    {
      name: "Dashboard Name",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.dashboard_name,
      sortable: true,
    },
    {
      name: "New Dashboard Name",
      // selector: (row) => row.new_dashboard_name,
      selector: row =>
        <TextInput
          value={row.new_dashboard_name}
          style={styles.input}
          placeholder="Enter new name"
          onChangeText={(text) => {
            var dashboard_report_id = row.id
            var new_dashboard_name = text
            console.log("text", "" + text)
            row.new_dashboard_name = text
            let data = tempArray.find(object => object['dashboard_report_id'] === row.id);
            // console.log("dataarray==>", data)
            if (data !== undefined) {
              const newState = tempArray.map(obj => {
                if (obj.dashboard_report_id === row.id) {
                  return { ...obj, new_dashboard_name: text };
                }
                return obj;
              })
              // console.log("newState", newState)
              setTempArray(newState)
            } else {
              let data = { dashboard_report_id, new_dashboard_name }
              // console.log("data", JSON.stringify(data))
              tempArray.push(data)
              // console.log("tempArray", JSON.stringify(tempArray))
            }
            console.log("tempArray", JSON.stringify(tempArray))
          }}
        ></TextInput>,
      sortable: true,
    },
  ];


  const custDataTable = () => {
    return (
      <View style={{ height: 100, width: '100%' }}>
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <View style={{ flexDirection: "row", width: "100%", marginTop: "5%" }}>
        {" "}
        <View style={{ flex: 4, margin: 10 }}>
          <Text style={styles.lableText}>
            Profile {">"}{" "}
            <Text onPress={() => navigation.navigate("ProjectProfile")}>
              {" "}
              Project Profile{" "}
            </Text>{" "}
            {">"} Edit Project Profile
          </Text>
        </View>
        {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={isMobile ? [
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 0,
              paddingHorizontal: 25,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center"
            },
          ] : [
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 0,
              paddingHorizontal: 25,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center"
            },
          ]}
        >
          <View
             style={isMobile ? {
              // flex: 1,
              justifyContent: "center",
              alignItems: "center",
              // flexDirection: "row",
              width: "100%"
            } : {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%"
            }}
          >
            <View style={isMobile ? { marginLeft: "10%" } : { flex: 1, marginLeft: "10%" }}>
              <View
                style={{
                  // backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                }}
              >
                <View
                  style={isMobile ? { flex: 1, alignItems: "flex-start", padding: 10, marginLeft: 10 } : { flex: 1, alignItems: "flex-start", padding: 10 }}
                >
                  {/* <Button title='Pick an image from camera roll'   color="#000000"
 onPress={pickImage} /> */}
                  <TouchableOpacity
                    style={{
                      backgroundColor: profileImage ? "" : "grey",
                      padding: 10,
                    }}
                    onPress={pickImage}
                  >
                    <Image
                      // source={{ uri: profileImage }}
                      source={
                        profileImage
                          ? { uri: profileImage }
                          : require("../../../assets/images/projectImg.png")
                      }
                      style={isMobile ? { width: 80, height: 80, borderWidth:1,  } : { width: 100, height: 100, borderWidth:1, }}
                    />
                  </TouchableOpacity>
                </View>

                <View style={isMobile ? { marginLeft: -45, marginTop: -15 } : null}>
                <Text style={styles.lableText}>Project Code </Text>
                <View style={isMobile ? { flexDirection: 'row', width: "115%", } : { flexDirection: 'row' }}>
                  <TextInput
                    style={[styles.input, { backgroundColor: "#DEDEDE" }]}
                    placeholder="Enter Project code"
                    value={ProjectID}
                    editable={false}
                    onChangeText={(value) => {
                      setProjectIDValid(false);
                      setProjectID(value);
                      if (value.length == 0) {
                        setProjectIDError("Please enter Project iD");
                      } else if (!alpha_numeric.test(value)) {
                        setProjectIDError("Please enter alphanumeric only");
                      } else {
                        setProjectIDValid(true);
                        setProjectIDError("");
                      }
                    }}
                  />
                  <View style={{ marginLeft: 0, padding: 10 }}>
                    {isOmegaLoading ? (
                      <ActivityIndicator
                        //visibility of Overlay Loading Spinner
                        color="#1DA1F2"
                        visible={isLoading}
                        //Text with the Spinner
                        textContent={"Loading..."}
                        size={40}
                      //  //Text style of the Spinner Text
                      //  textStyle={styles.spinnerTextStyle}
                      />
                    ) : (
                      <IconRefresh
                        name="refresh"
                        size={30}
                        onPress={() => getOmegaIdList()}
                      />
                    )}{" "}
                  </View>
                </View>

                {/* <View> */}
                <Text style={[styles.error_text]}>{ProjectNameError}</Text>
                {/* </View> */}
                </View>

                <View style={isMobile ? { marginLeft: -45, marginTop: -15 } : null}>
                <Text style={styles.lableText}>Project Type</Text>
                <View>
                  <Dropdown
                    ref={dropdownRefProjectType}
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "110%",
                      borderWidth: 1,
                      borderRadius: 2,
                      height: 40,
                      outlineStyle: "none",
                      padding: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    } : styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={ProjectTypeData}
                    search
                    maxHeight={300}
                    labelField="type"
                    valueField="id"
                    placeholder={
                      ProjectTypeValue
                        ? ProjectTypeValue
                        : "Select Project type"
                    }
                    searchPlaceholder="Search..."
                    value={ProjectTypeValue}
                    onChange={(item) => {
                      console.log("item.type", item.type)
                      console.log("item.id", item.id)
                      setProjectTypeValue(item.type);
                      setProjectTypeValueiD(item.id);
                      setProjectTypeError("");
                    }}
                    renderItem={renderProjectTypeListItems}
                  />
                  <Text style={[styles.error_text]}>{ProjectTypeError}</Text>
                </View>
                </View>
              
                <View style={isMobile ? { marginLeft: -45, marginTop: -15 } : null}>
                <Text style={styles.lableText}>Contractor Name </Text>
                <TextInput
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "110%",
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 10,
                    marginTop: 4,
                    marginBottom: 6,
                  } : styles.input}
                  placeholder="Enter Contractor name"
                  value={ContractorName}
                  onChangeText={(value) => {
                    setContractorNameValid(false);
                    setContractorName(value);
                    if (value.length == 0) {
                      setContractorError("Please enter Contractor name");
                    }
                    // else if (!alpha_pattern.test(value)) {
                    //   setContractorError("Please enter character only");
                    // }
                    else {
                      setContractorNameValid(true);
                      setContractorError("");
                    }
                  }}
                />
                </View>

                <Text style={[styles.error_text]}>{ContractorError}</Text>
                
                <View style={isMobile ? { marginLeft: -45, marginTop: -15 } : null}>
                <Text style={styles.lableText}>Omega name</Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: "100%" }}>
                    <View>
                      <Dropdown
                        ref={dropdownRefOmegaiD}
                        style={isMobile ? {
                          borderColor: "#c3c3c3",
                          width: "110%",
                          borderWidth: 1,
                          borderRadius: 2,
                          height: 40,
                          outlineStyle: "none",
                          padding: 10,
                          marginTop: 5,
                          marginBottom: 5,
                        } : styles.dropdown}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={OmegaIdData}
                        search
                        maxHeight={300}
                        labelField="device_name"
                        valueField="device_name"
                        placeholder={
                          OmegaIdValue ? OmegaIdValue : "Select Omega name"
                        }
                        searchPlaceholder="Search..."
                        value={OmegaIdValue}
                        onChange={(item) => {
                          setOmegaIdValue(item.device_name);
                          setOmegaValueiD(item.id);
                          setOmegaError("");

                          getOmegaDetails(item.id);



                          setLatitude(item.lat);
                          setLongitude(item.long);
                          setProjectIdOmega(item.project_id);

                          // setShouldShow(false);
                          // console.log("omega id value---", item.id, item.device_name);
                        }}
                        renderItem={renderOmegaIdListItems}
                      />
                      <Text style={[styles.error_text]}>{OmegaError}</Text>
                    </View>
                  </View>


                </View>
                </View>
              </View>
            </View>
            <View
              style={isMobile ? {
                // flex: 1,
                // marginTop: 53,
                // backgroundColor: "#F2F2F2",
                borderRadius: 10,
                padding: 10,
                // margin: 150,
                marginBottom: "0%",
              } : {
                flex: 1,
                marginTop: 53,
                // backgroundColor: "#F2F2F2",
                borderRadius: 10,
                padding: 10,
                margin: 150,
                marginBottom: "0%",
              }}
            >
              <View style={isMobile ? { marginLeft: -10, marginTop: -30 } : null}>
                <Text style={styles.lableText}>Project Name</Text>
                <TextInput
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "105%",
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 10,
                    marginTop: 4,
                    marginBottom: 6,
                    backgroundColor: "#DEDEDE" 
                  } : [styles.input, { backgroundColor: "#DEDEDE" }]}
                  // placeholder="Enter Project name"
                  value={ProjectName}
                  editable={false}
                />

                {/* <View> */}
                <Text style={[styles.error_text]}>{ProjectNameError}</Text>
                {/* </View> */}
              </View>

              <View style={isMobile ? { marginLeft: -15,marginTop: -10 } : null} >
              <Text style={styles.lableText}>Client Name </Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "105%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder="Enter Client name"
                value={ClientName}
                onChangeText={(value) => {
                  setClientNameValid(false);
                  setClientName(value);
                  if (value.length == 0) {
                    setClientnameError("Please enter Client name");
                  }
                  // else if (!alpha_pattern.test(value)) {
                  //   setClientnameError("Please enter character only");
                  // }
                  else {
                    setClientNameValid(true);
                    setClientnameError("");
                  }
                }}
              />
              {/* <View> */}
              <Text style={[styles.error_text]}>{ClientnameError}</Text>
              {/* </View> */}
              </View>
              
              <View style={isMobile ? { marginLeft: -15,marginTop: -10 } : null}>
              <Text style={styles.lableText}>Project Details</Text>
              <TextInput
                style={isMobile ? {
                  borderColor: "#c3c3c3",
                  width: "105%",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 10,
                  marginTop: 4,
                  marginBottom: 6,
                } : styles.input}
                placeholder="Enter Projects details"
                value={ProjectDetails}
                onChangeText={(value) => {
                  setProjectDetailsValid(false);
                  setProjectDetails(value);
                  if (value.length == 0) {
                    setProjectDetailsError("Please enter Project details ");
                  }
                  // else if (!alpha_numeric.test(value)) {
                  //   setProjectDetailsError("Please enter character only");
                  // }
                  else {
                    setProjectDetailsValid(true);
                    setProjectDetailsError("");
                  }
                }}
              />
              {/* <View> */}
              <Text style={[styles.error_text]}>{ProjectNameError}</Text>
              </View>
             
              <View style={isMobile ? { marginLeft: -15,marginTop: -10 } : null}>
                <Text style={styles.lableText}>Location </Text>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      marginRight: 5,
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                      backgroundColor: "#DEDEDE" 
                    } : [
                      styles.inputLocation,
                      { backgroundColor: "#DEDEDE" },
                    ]}
                    // placeholder="Latitude"
                    maxLength={10}
                    editable={false}
                    keyboardType={"numeric"}
                    value={Lat}
                  />
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      marginRight: 5,
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                      backgroundColor: "#DEDEDE" 
                    } : [
                      styles.inputLocation,
                      { backgroundColor: "#DEDEDE" },
                    ]}
                    // placeholder="Longitude"
                    maxLength={10}
                    keyboardType={"numeric"}
                    editable={false}
                    keyboardType={"numeric"}
                    value={Long}
                  />
                  <IconLoc
                    name="my-location"
                    size={20}
                    style={{ margin: 10 }}
                  />
                </View>
              </View>
              {/* <View style={isMobile ? { marginLeft: -15,marginTop: -10 } : null}>
                  <Text style={styles.lableText}>Based On</Text>
                  <View style={isMobile ? { flexDirection: "row", marginTop: 5 ,alignItems:'center'} : { flexDirection: "row", marginTop: 10, alignItems:'center' }}>
                    <Text>Omega</Text>
                    <RadioButton 
                     value={isAssignchecked}
                     status={isAssignchecked === 1 ? "checked" : "unchecked"}
                     onPress={() => setAssignChecked(1)}
                    />
                    <Text>IMEI</Text>
                    <RadioButton 
                     value={isAssignchecked}
                     status={isAssignchecked === 2 ? "checked" : "unchecked"}
                     onPress={() => setAssignChecked(2)}
                    />
                    <Text>Both</Text>
                    <RadioButton
                     value={isAssignchecked}
                     status={isAssignchecked === 3 ? "checked" : "unchecked"}
                     onPress={() => setAssignChecked(3)}
                    />
                  </View>
                </View> */}
            </View>
          </View>

          {/* {shouldShow ? null : */}
          {DataRecord.length > 0 &&
            <View style={{ marginTop: 20, width: "41%", alignSelf: "center" }}>
              <TabView
                navigationState={{ index, routes }}
                renderScene={renderScene}
                onIndexChange={setIndex}
                initialLayout={{ width: layout.width }}
                // renderLabel={({ route, color }) => (
                //   <Text style={{ color: 'black', margin: 8 }}>
                //     {route.title}
                //   </Text>
                // )}
                renderTabBar={(props) => (
                  <TabBar
                    {...props}
                    indicatorStyle={{ backgroundColor: "#2c7bbe", height: 50 }}
                    renderLabel={({ route, color }) => (
                      <Text style={{ color: "Black", margin: 8 }}>
                        {route.title}
                      </Text>
                    )}
                    style={{ backgroundColor: "#F6F9F9" }}
                  />
                )} // <-- add this line
              />
            </View>
          }
          {/* } */}
          {/* </ScrollView> */}
          <View
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              backgroundColor: "#F6F9F9",
              width: "60%",
              marginBottom: 50
            }}
          >
            {" "}
            <View style={[styles.card, { flexGrow: 1 }]}>
              <View
                style={[
                  styles.shadowProp,
                  {
                    backgroundColor: "white",
                  },
                ]}
              >
                <View
                  style={isMobile ? {
                    width: "180%",
                    // flex: 1,
                    backgroundColor: "#F6F9F9",
                    marginLeft:-57
                  } : {
                    width: "100%",
                    // flex: 1,
                    backgroundColor: "#F6F9F9",
                  }}
                >
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: "#e3e0e0",
                    }}
                  >
                    <View>
                      <DataTable
                        // title="Country"
                        columns={columnsDashboardName}
                        data={dashboardName}
                        customStyles={customDashboardNameStyles}
                        // expandableRows
                        // expandableRowsComponent={() =>
                        //   <View style={{ width: '100%', flexDirection: 'row' }}>
                        //     <DataTable
                        //       columns={columnsDashboardName}
                        //       data={dashboardName}
                        //       customStyles={customDashboardNameStyles}
                        //     />
                        //   </View>
                        // }
                        pagination
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                      />
                    </View>
                  </View>
                </View>
                {/* </ScrollView> */}
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginHorizontal: 20,
              marginBottom: 20,
            }}
          >
            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "70%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => CheckEnteredData()}
            >
              <Text style={styles.btnText}>Update</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "70%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => navigation.navigate("ProjectProfile")}
            >
              <Text style={styles.btnText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 150,
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  inputLocation: {
    borderColor: "#c3c3c3",
    width: "40%",
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 5,
    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  input: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 4,

    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "100%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,
    // marginVertical: 10,
    // paddingBottom: "48%",

    width: "100%",
    flex: 1,
    marginVertical: 0,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 17,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
});
