import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  Alert,
  ActivityIndicator,
  Switch,
  useWindowDimensions,
  Image,
  Dimensions,
} from "react-native";
import { RadioButton, Button } from "react-native-paper";
import DataTable from "react-data-table-component";
import moment from "moment";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";

import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { DatePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";

import SuccessPopup from "../../../components/SuccessPopup";

import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";
import IconLoc from "react-native-vector-icons/MaterialIcons";
import { useSelector } from "react-redux";
import IconRefresh from "react-native-vector-icons/FontAwesome";

export default function EditDeviceProfile({ navigation, route }) {
  var idProject = useSelector((state) => state.idUpdating);

  const { DeviceProfileId } = route?.params;
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);

  const [genderchecked, setGenderChecked] = useState(1);
  const [isMarriedchecked, setMaritialChecked] = useState(1);
  const [isstatuschecked, setStatusChecked] = useState(1);
  const [isAssignchecked, setAssignChecked] = useState(1);
  const [projectId, setProjectId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [empCode, setEmpCode] = useState("");
  const [deviceCode, setDeviceCode] = useState("");

  const [DeviceProfileID, setDeviceProfileID] = useState("1");
  const [isDeviceProfileIDValid, setDeviceProfileIDValid] = useState(false);
  const [isDeviceProfileIDEntered, setDeviceProfileIDEntered] = useState(false);

  const [DeviceProfileName, setDeviceProfileName] = useState("");
  const [isDeviceProfileNameValid, setDeviceProfileNameValid] = useState(true);
  const [isDeviceProfileNameEntered, setDeviceProfileNameEntered] =
    useState(false);

  const [EmailId, setEmailId] = useState("");
  const [isEmailIdValid, setEmailIdValid] = useState(false);
  const [isEmailIdEntered, setEmailIdEntered] = useState(false);

  const [DeviceProfileCode, setDeviceProfileCode] = useState("");
  const [isDeviceProfileCodeValid, setDeviceProfileCodeValid] = useState(true);
  const [isDeviceProfileCodeEntered, setDeviceProfileCodeEntered] =
    useState(false);

  const [Location, setLocation] = useState("");
  const [isLocationValid, setLocationValid] = useState(false);
  const [isLocationEntered, setLocationEntered] = useState(false);

  const [isZoneIDValid, setZoneIdValid] = useState(false);

  const [ZoneId, setZoneId] = useState([]);
  const [ZoneValue, setZoneValue] = useState();
  const [ZoneValueId, setZoneValueId] = useState();

  const [ZoneNameData, setZoneNameData] = useState([]);

  const [SubzoneValue, setSubzoneValue] = useState();
  const [SubzoneValueId, setSubzoneValueId] = useState();
  const [subzoneData, setSubzoneData] = useState([]);

  const [isFocus, setIsFocus] = useState(false);

  // Master Id's and names
  const [countryValue, setCountryValue] = useState("");
  const [countryValId, setCountryValId] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [stateValId, setStateValId] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [cityValId, setCityValId] = useState("");
  const [tahasilValue, setTahasilValue] = useState("");
  const [tahasilValId, setTahasilValId] = useState("");
  const [VillageValue, setVillageValue] = useState("");
  const [VillageValId, setVillageValId] = useState("");
  const [KhasaraValue, setKhasaraValue] = useState("");
  const [KhasaraValId, setKhasaraValId] = useState("");

  //Dropdown value pickup
  const [OmegaIdValue, setOmegaIdValue] = useState(null);
  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegavalueId, setOmegaValueiD] = useState("");
  const [OmegaDeviceId, setOmegaDeviceiD] = useState("");

  const dropdownRefOmegaiD = useRef();
  const dropdownRefZone = useRef();

  const [DataRecord, setDataRecord] = useState([]);
  const [OmegaDetails, setOmegaDetails] = useState([]);
  const [AnalogInput, setAnalogInput] = useState([]);
  const [DigitalInput, setDigitalInput] = useState([]);

  const closeMenu = () => {
    dropdownRefOmegaiD.current.close();
    dropdownRefZone.current.close();
  };
  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);
  // const omegaData = [
  //     { label: 'OM000000030', value: '1001' },
  //     { label: 'OM000000031', value: '2001' },
  //     { label: 'OM000000032', value: '3001' },
  //     { label: 'OM000000033', value: '4001' },
  //     { label: 'OM000000034', value: '5001' },
  // ];
  //Error message
  const [ZoneIdError, setZoneIdError] = useState("");
  const [SubZoneIdError, setSubZoneIdError] = useState("");

  const [OmegaError, setOmegaError] = useState("");

  const [Lat, setLatitude] = useState("");
  const [Long, setLongitude] = useState("");
  const [DeviceProfileIdError, setDeviceProfileIdError] = useState("");

  const [DeviceProfileNameError, setDeviceProfileNameError] = useState("");
  const [DeviceProfileCodeError, setDeviceProfileCodeError] = useState("");
  const [DeviceProfileLocationError, setDeviceProfileLocationError] =
    useState("");
  const [isClickedChecked,setIsClickChecked]=useState(1);  
  const [IMEIName,setIMEIName]= useState(""); 
  const [IMEINameError,setIMEINameError] = useState("")
  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  //DOb
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  //DOJ
  const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);
  const [joinOpen, setJoinOpen] = React.useState(false);

  const onDismissJoin = React.useCallback(() => {
    setOpen(false);
  }, [setJoinOpen]);

  const onConfirmJoin = React.useCallback(
    (params) => {
      setJoinOpen(false);
      setJoinDate(params.date);
    },
    [setJoinOpen, setJoinDate]
  );
  //Regex
  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_pattern = new RegExp(/^[A-Za-z0-9_@./#&+-]*$/);
  // var alpha_pattern = new RegExp(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/); THIS

  var alpha_pattern = new RegExp(/^[a-zA-Z0-9!@#$%^&-]+(\s[a-zA-Z0-9!@#$%^&-]+)?$/);

  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );
  // var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  // var number_regex = new RegExp(/^[0-9]*$/);
  const regex = /^[0-9]*$/;

  const FirstRoute = () => (
    <View style={{ flex: 1, backgroundColor: "#ff4081" }}>
      {" "}
      <Text>tab1 </Text>
    </View>
  );

  const SecondRoute = () => (
    <View style={{ flex: 1, backgroundColor: "#673ab7" }}>
      <Text>tab2 </Text>
    </View>
  );
  const ThirdRoute = () => (
    <View style={{ flex: 1, backgroundColor: "#eee" }}>
      <Text>tab2 </Text>
    </View>
  );

  // const renderScene = SceneMap({
  //   first: FirstRoute,
  //   second: SecondRoute,
  //   third: ThirdRoute,
  // });

  const DigitalOutputRoute = () => (

    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >


              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={OmegaDetails}
                  customStyles={customStyles}
                  pagination
                />
              </View>

            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View >

  );

  const AnalogInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>

                <DataTable
                  // title="Country"
                  columns={columnsAnalog}
                  data={AnalogInput}
                  customStyles={customStyles}
                  pagination
                />

              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
  const DigitalInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnDigitalInput}
                  data={DigitalInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const renderScene = SceneMap({
    DigitalOutput: DigitalOutputRoute,
    DigitalInput: DigitalInputRoute,
    AnalogInput: AnalogInputRoute,
  });

  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "DigitalOutput", title: "Digital Output" },

    { key: "DigitalInput", title: "Digital Input" },
    { key: "AnalogInput", title: "Analog Input" },
  ]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      getOmegaIdList();
      getDeviceProfileById();
    }, [idProject])
  );
  //Get device  data by id
  const getDeviceProfileById = async () => {
    setIsLoading(true);
    try {
      var DeviceProfileData = JSON.stringify({
        // "id": DeviceProfileId,
        // "module": "devices_master"

        module: "device_profile",
        relation: [
          {
            module: "device_profile_details",
            subModule: "devices_master",
          },
          {
            module: "zone_master",
          },
          {
            module: "sub_zone_master",
          },
        ],
        id: DeviceProfileId,
      });
      const DeviceProfileDataById = await postData(
        DeviceProfileData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      console.log("DeviceProfileDataById==>", DeviceProfileDataById);
      var code = DeviceProfileDataById.code;
      if (code == 1) {


        if (DeviceProfileDataById.data[0].device_profile_details.length > 0 && DeviceProfileDataById.data[0].device_profile_details[0].devices_master !== null) {
          setOmegaIdValue(
            DeviceProfileDataById.data[0].device_profile_details[0].devices_master
              .device_name
          );
          setOmegaDeviceiD(
            DeviceProfileDataById.data[0].device_profile_details[0].devices_master
              .device_id
          );

          console.log("device_id==>", DeviceProfileDataById.data[0].device_profile_details[0].devices_master
            .id)

          getOmegaDetails(DeviceProfileDataById.data[0].device_profile_details[0].devices_master
            .id)

          setOmegaValueiD(
            DeviceProfileDataById.data[0].device_profile_details[0].devices_master
              .omega_id
          );
          setLatitude(
            DeviceProfileDataById.data[0].device_profile_details[0].devices_master
              .lat
          );
          setLongitude(
            DeviceProfileDataById.data[0].device_profile_details[0].devices_master
              .long
          );
        } else {
          let value = "None"
          setOmegaIdValue(value);
          setOmegaValueiD("");
          setOmegaDeviceiD("0");
        }

        setDeviceProfileCode(DeviceProfileDataById.data[0].device_profile_code);
        setDeviceProfileName(DeviceProfileDataById.data[0].device_name);
        // setDeviceProfileCode(DeviceProfileDataById.data.device_number);
        setAssignChecked(DeviceProfileDataById.data[0].assignment_type);
        setZoneValue(DeviceProfileDataById.data[0].zone_id);
        getDependentZoneMaster(DeviceProfileDataById.data[0].zone_id);
        setSubzoneValue(DeviceProfileDataById.data[0].sub_zone_id);
        setStatusChecked(DeviceProfileDataById.data[0].active);
      } else {
        setIsLoading(false);
        //alert(DeviceProfileDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error==>", error);
    }
  };
  const getAllZoneProfileData = async (pid) => {
    setIsLoading(true);
    try {
      var getZoneData = JSON.stringify({
        condition: {
          project_id: pid,
        },
        module: "zone_master",
        relation: [],
      });
      const ZoneNameResponse = await postData(
        getZoneData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = ZoneNameResponse.code;
      if (code == 1) {
        // console.log("ZoneNameResponse list====>", ZoneNameResponse.data);

        setZoneNameData(ZoneNameResponse.data);
      } else {
        setIsLoading(false);
        //alert(ZoneNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const getDependentZoneMaster = async (zone_id) => {
    // console.log("zone_idmasters", zone_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        column: "zone_id",

        value: zone_id,

        module: "sub_zone_master",
      });
      const dependentZoneRes = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentZoneRes.code;
      if (code == 1) {
        // console.log("getDependentCountryMaster======", dependentZoneRes.data);
        // setCountryLength(dependentZoneRes.data.length);
        setSubzoneData(dependentZoneRes.data);
      } else {
        setIsLoading(false);
        //alert(dependentZoneRes.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");
    //console.log("projectData",projValue);
    if (projValue != null) {
      setProjectId(projValue);
      // setUserType(userDetailsresponse.user_type);
      getOmegaDependentByProjectId(projValue);
      getAllZoneProfileData(projValue);
    } else
      if (jsonValue != null) {
        var userDetailsresponse = JSON.parse(jsonValue);
        // console.log("userDetailsresponse==>", userDetailsresponse);
        getAllZoneProfileData(userDetailsresponse.project_selected);
        setEmpCode(userDetailsresponse.employee_code);
        setProjectId(userDetailsresponse.project_selected);
        getOmegaDependentByProjectId(userDetailsresponse.project_selected);
      }
  };

  const getOmegaDependentByProjectId = async (project_id) => {
    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        project_id: project_id,
      });
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getUniqueOmegaIds"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("Bermad zone Omega list api====>", OmegaIdList.data);
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          omega_id: "",
          device_name: "None",
          lat: "",
          long: "",

        });
        setOmegaIdData(mergeData);
        // setOmegaIdData(OmegaIdList.data);
      } else {
        setIsLoading(false);
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          omega_id: "",
          device_name: "None",
          lat: "",
          long: "",

        });
        setOmegaIdData(mergeData);
        //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const getIMEIName = async ()=>{
    console.log("on click of this refresh IMEI name");
  }

  const renderZoneIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {OmegaIdData.length > 0 && <Text style={styles.textItem}>{item.zone_name}</Text>}

        {item.id === ZoneValueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderSubZoneIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.sub_zone_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.sub_zone_name}</Text>
        )}
        {item.id === SubzoneValueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderOmegaIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {item.value === OmegaDeviceId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const CheckEnteredData = async () => {
    setDeviceProfileNameEntered(true);
    setDeviceProfileCodeEntered(true);

    // console.log("check nenter data");

    //setOmegaId(OmegaIds);
    if (
      isDeviceProfileNameValid
      // &&
      // isDeviceProfileCodeValid
    ) {
      // console.log("success");
      updateDeviceProfileData();
      setZoneIdError("");
      setSubZoneIdError("");
      // setDeviceProfileIdError("");
      setDeviceProfileNameError("");
      setIMEINameError("");
      // setDeviceProfileCodeError("");
    } else {


      if (DeviceProfileName == "") {
        setDeviceProfileNameError("Please enter zone name");
      }
      if (ZoneValue == "") {
        setZoneIdError("Please Select Zone name");
      }
      if (SubzoneValue == "") {
        setSubZoneIdError("Please Select Subzone name");
      }
      if(IMEIName == ""){
        setIMEINameError("Please enter IMEI name");
      }
      // DeviceProfile id
      // if (DeviceProfileID == "") {
      //     setDeviceProfileIdError("Please enter Device Profile ID");
      // }
      //DeviceProfile code
      // if (DeviceProfileCode == "") {
      //     setDeviceProfileCodeError("Please enter zone code ");
      // }
    }
  };
  // Get all OMEGA list Api
  const getOmegaIdList = async () => {
    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        module: "devices_master",
        relation: [],
      });
      const OmegaIdList = await postData(OmegaIdData, "masters/getMasterList");
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("Omega list api====>", OmegaIdList.data);

        // setOmegaIdData(OmegaIdList.data);
      } else {
        setIsLoading(false);
        //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //DeviceProfile Profile update Api

  const updateDeviceProfileData = async () => {
    setIsLoading(true);
    try {
      var ZoneMasterData = JSON.stringify({
        //module: "device_profile",
        id: DeviceProfileId,
        modelName:"device_profile",
        // data: {
        //   project_id: projectId,
        //   assignment_type: isAssignchecked,
        //   zone_id: ZoneValueId,
        //   sub_zone_id: SubzoneValueId,
        //   device_name: DeviceProfileName,
        //   active: isstatuschecked,
        //   based_on: isClickedChecked,
        //   imei_name: IMEIName,
        // },
        inputData: {
          project_id: projectId,
          assignment_type: isAssignchecked,
          zone_id: ZoneValueId,
          sub_zone_id: SubzoneValueId,
          device_name: DeviceProfileName,
          active: isstatuschecked,
        },
        // submodule: "device_profile_details",
        // submodule_field_name: "device_profile_id",
        // submoduledata: [
        //   {
        //     id: DeviceProfileId,
        //     data: {
        //       device_id: OmegaDeviceId,
        //       omega_id: OmegavalueId,
        //       active: 1,
        //     },
        //   },
        // ],
        relation: {
          subModelName: "device_profile_details",
          subModelInputData: [
            {
              id: DeviceProfileId,
              device_id: OmegaDeviceId,
              omega_id: OmegavalueId,
              active: 1,
            },
          ],
        },
      });
       console.log("device payload UPDATE===", ZoneMasterData);
      const DeviceProfileProfileResponse = await postData(
        ZoneMasterData,
        "masters/profileCreationAndUpdation"//profileCreateUpdate"
      );

      setIsLoading(false);

      var code = DeviceProfileProfileResponse.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        // setIsVisible(false);
        // console.log("device profile update", DeviceProfileProfileResponse.data);
        navigation.navigate("DeviceProfile");
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(DeviceProfileProfileResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Get details when click on omega id
  const getOmegaDetails = async (deviceId) => {
    setIsLoading(true);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getOmegaDetails"
      );
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      console.log("OmegaDetailsResponse-->", OmegaDetailsResponse)
      if (code == 1) {
        setDataRecord(OmegaDetailsResponse.data);
        setOmegaDetails(OmegaDetailsResponse.data[0].valves);
        setAnalogInput(OmegaDetailsResponse.data[0].analogInput);
        setDigitalInput(OmegaDetailsResponse.data[0].digitalInput);
      } else {
        setDataRecord(OmegaDetailsResponse.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error==>", error)
    }
  };


  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <View style={{ flexDirection: "row", width: "100%", marginTop: "0%" }}>
        {" "}
        <View style={{ flex: 4, margin: 10 }}>
          <Text style={styles.lableText}>
            Profile {">"}{" "}
            <Text onPress={() => navigation.navigate("DeviceProfile")}>
              {" "}
              Device Profile{" "}
            </Text>{" "}
            {">"} Edit Device Profile
          </Text>
        </View>
        {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={isMobile ? [styles.card, { flexGrow: 1 }] : [styles.card, { flexGrow: 1 }]}>
        <View
          style={isMobile ? [
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 35,
              paddingHorizontal: 10,
            },
          ] : [
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 45,
              paddingHorizontal: 25,
            },
          ]}
        >
          <View
            style={isMobile ? {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            } : {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View
              style={isMobile ? {
                flex: 1,
                marginRight: 20,
                marginBottom: "3%",
                marginTop: "-56%",
              } : {
                flex: 1,
                marginRight: 20,
                marginBottom: "3%",
                marginTop: "-1%",
              }}
            >
              <View
                style={{
                  // backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                }}
              >
                {/* <View style={isMobile ? { marginTop: "-35%", } : null}>
                <Text style={isMobile ? [styles.lableText, {}] : styles.lableText} numberOfLines={1}>Based on</Text> 
                <View style={isMobile ? { flexDirection: "row", marginTop: 5 ,alignItems:'center'} : { flexDirection: "row", marginTop: 10, alignItems:'center' }}>
                    <Text>Omega</Text>
                    <RadioButton 
                     value={isClickedChecked}
                      status={isClickedChecked === 1 ? "checked" : "unchecked"}
                      onPress={() => setIsClickChecked(1)}
                    />
                    <Text>IMEI</Text>
                    <RadioButton 
                     value={isClickedChecked}
                     status={isClickedChecked === 2 ? "checked" : "unchecked"}
                      onPress={() => setIsClickChecked(2)}
                    />
                  </View>
                </View> */}
                {/* {isClickedChecked === 1 && ( */}
                <View style={isMobile ? { marginTop: "0%", } : null}>
                  <Text style={isMobile ? [styles.lableText, { marginTop: "3%" }] : [styles.lableText]}>Omega Name</Text>
                  <Dropdown
                    ref={dropdownRefOmegaiD}
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "260%",
                      borderWidth: 1,
                      borderRadius: 2,
                      height: 40,
                      padding: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    } : styles.dropdown}
                    placeholderStyle={isMobile ? { fontSize: 12 } : styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={OmegaIdData}
                    search
                    maxHeight={300}
                    labelField="device_name"
                    valueField="device_name"
                    placeholder={
                      OmegaIdValue ? OmegaIdValue : "Select Omega name"
                    }
                    searchPlaceholder="Search..."
                    value={OmegaIdValue}
                    onChange={(item) => {
                      setOmegaIdValue(item.device_name);
                      setOmegaValueiD(item.omega_id);
                      setOmegaDeviceiD(item.id);
                      setOmegaError("");
                      getOmegaDetails(item.id);
                      // getOmegaDetails(item.id)
                      // console.log("omega id value---", item.id);
                    }}
                    renderItem={renderOmegaIdListItems}
                  />
                  {/* {OmegaIdValue} */}
                  {/* {OmegavalueId} */}
                  <Text style={[styles.error_text]}>{OmegaError}</Text>
                </View>
                {/* )} */}
                {/* {isClickedChecked === 2 && (
                 <View style={isMobile ? { marginTop: "0%", } : null}>
                  <Text style={isMobile ? [styles.lableText, { marginTop: "3%" }] : [styles.lableText]}>IMEI name</Text>
                 <View style={isMobile ? { flexDirection: 'row', width: "115%", } : { flexDirection: 'row', }}>
                     <TextInput
                        style={isMobile ? {
                          flex: 1,
                          borderColor: "#c3c3c3",
                          //width: "100%",
                          ...(isMobile ? {} : { width: "100%" }) ,
                          borderWidth: 1,
                          borderRadius: 4,
                          padding: 10,
                          marginTop: 4,
                          marginBottom: 6,
                      } : styles.input}
                         placeholder="Enter IMEI name"
                         onChangeText={(value)=>{
                             setIMEIName(value);
                             if (value.length == 0) {
                              setIMEINameError("Please enter IMEI name");
                            } else{
                              setIMEINameError("");
                            }
                         }}
                     />
                     <View style={{  marginLeft: 0, padding: 10  }}>
                     <IconRefresh
                         name="refresh"
                         size={30}
                         onPress={()=> getIMEIName()}
                     />
                     </View>
                 </View>
                 <Text style={[styles.error_text]}>
                    {IMEINameError}
                  </Text>
                 </View>
               )} */}
                <View style={isMobile ? { marginTop: "0%", } : null}>
                  <Text style={isMobile ? [styles.lableText, { marginTop: "3%" }] : [styles.lableText]}>Location</Text>
                  <View style={isMobile ? { flexDirection: "row", width: "200%", } : { flexDirection: "row" }}>
                    <TextInput
                      style={isMobile ? {
                        borderColor: "#c3c3c3",
                        width: "80%",
                        borderWidth: 1,
                        borderRadius: 4,
                        marginRight: 0,
                        padding: 10,
                        marginTop: 4,
                        marginBottom: 6,
                        backgroundColor: "#DEDEDE"
                      } : [
                        styles.inputLocation,
                        { backgroundColor: "#DEDEDE" },
                      ]}
                      placeholder="Lat"
                      maxLength={10}
                      editable={false}
                      keyboardType={"numeric"}
                      value={Lat}
                    />
                    <TextInput
                      style={isMobile ? {
                        borderColor: "#c3c3c3",
                        width: "80%",
                        borderWidth: 1,
                        borderRadius: 4,
                        marginRight: 5,
                        padding: 10,
                        marginTop: 4,
                        marginBottom: 6,
                        marginLeft: "10%",
                        backgroundColor: "#DEDEDE"
                      } : [
                        styles.inputLocation,
                        { backgroundColor: "#DEDEDE" },
                      ]}
                      placeholder="Long"
                      maxLength={10}
                      editable={false}
                      keyboardType={"numeric"}
                      value={Long}
                    />
                    <IconLoc
                      name="my-location"
                      size={20}
                      style={{ margin: 10 }}
                    />
                  </View>
                </View>
                

                {isAssignchecked === 1 && (
                  <View style={isMobile ? { marginTop: "0%" } : null}>
                    <Text style={styles.lableText}>Zone </Text>
                    <View>
                      <Dropdown
                        ref={dropdownRefZone}
                        style={isMobile ? {
                          borderColor: "#c3c3c3",
                          width: "260%",
                          borderWidth: 1,
                          borderRadius: 2,
                          height: 40,
                          padding: 10,
                          marginTop: 5,
                          marginBottom: 5,
                        } : styles.dropdown}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={ZoneNameData}
                        search
                        maxHeight={300}
                        labelField="zone_name"
                        valueField="id"
                        placeholder={ZoneValue ? ZoneValue : "Select Zone Id"}
                        searchPlaceholder="Search..."
                        value={ZoneValue}
                        onChange={(item) => {
                          setZoneValue(item.zone_name);
                          setZoneValueId(item.id);
                          getDependentZoneMaster(item.id);
                          setZoneIdError("");
                          // console.log("zone id value---", item.id);
                        }}
                        renderItem={renderZoneIdListItems}
                      />

                      <Text style={[styles.error_text]}>{ZoneIdError}</Text>
                    </View>
                  </View>
                )}

                {isAssignchecked === 2 && (
                  <View style={isMobile ? { marginTop: "0%" } : null}>
                    <Text style={styles.lableText}>Zone </Text>
                    <View>
                      <Dropdown
                        ref={dropdownRefZone}
                        style={isMobile ? {
                          borderColor: "#c3c3c3",
                          width: "260%",
                          borderWidth: 1,
                          borderRadius: 2,
                          height: 40,
                          padding: 10,
                          marginTop: 5,
                          marginBottom: 5,
                        } : styles.dropdown}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={ZoneNameData}
                        search
                        maxHeight={300}
                        labelField="zone_name"
                        valueField="id"
                        placeholder={ZoneValue ? ZoneValue : "Select Zone Id"}
                        searchPlaceholder="Search..."
                        value={ZoneValue}
                        onChange={(item) => {
                          setZoneValue(item.zone_name);
                          setZoneValueId(item.id);
                          getDependentZoneMaster(item.id);
                          setZoneIdError("");
                          // console.log("zone id value---", item.id);
                        }}
                        renderItem={renderZoneIdListItems}
                      />

                      <Text style={[styles.error_text]}>{ZoneIdError}</Text>
                    </View>
                  </View>
                )}

                <View style={isMobile ? { marginTop: "0%" } : null}>
                  <Text style={isMobile ? [styles.lableText, { marginTop: 0 }] : [styles.lableText]}>Status </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.statusNames, { color: "#05D6B3" }]}>
                      Active
                    </Text>
                    <RadioButton
                      value={isstatuschecked}
                      status={isstatuschecked === 1 ? "checked" : "unchecked"}
                      onPress={() => setStatusChecked(1)}
                    />
                    <Text style={[styles.statusNames, { color: "#E11A1A" }]}>
                      InActive
                    </Text>
                    <RadioButton
                      value={isstatuschecked}
                      status={isstatuschecked === 0 ? "checked" : "unchecked"}
                      onPress={() => setStatusChecked(0)}
                    />{" "}
                  </View>
                </View>
              </View>
            </View>
            <View
              style={isMobile ? { flex: 1, marginTop:  "175%", height: 0 } : {
                flex: 1,
                marginBottom: "auto",
              }}
            >
              <View>
                {/* <Text style={styles.lableText}>Location </Text>
                <View style={{ flexDirection: "row" }}>

                  <TextInput
                    style={styles.inputLocation}
                    placeholder="Lat"
                    maxLength={10}
                    keyboardType={"numeric"}
                    onChangeText={(value) => {
                      setLocationValid(false);
                      setLocation(value);
                      if (value.length == 0) { setDeviceProfileLocationError("Please enter mobile number") }
                      else if (!regex.test(value)) {
                        setDeviceProfileLocationError("Please enter number only")
                      }
                      else if (value.length > 0 && value.length <= 9) {
                        setDeviceProfileLocationError('Please enter 10 digit number')
                      }
                      else {
                        setLocationValid(true);
                        setDeviceProfileLocationError('')
                      }
                    }}
                  />
                  <TextInput
                    style={styles.inputLocation}
                    placeholder="Long"
                    maxLength={10}
                    keyboardType={"numeric"}
                    onChangeText={(value) => {
                      setLocationValid(false);
                      setLocation(value);
                      if (value.length == 0) { setDeviceProfileLocationError("Please enter mobile number") }
                      else if (!regex.test(value)) {
                        setDeviceProfileLocationError("Please enter number only")
                      }
                      else if (value.length > 0 && value.length <= 9) {
                        setDeviceProfileLocationError('Please enter 10 digit number')
                      }
                      else {
                        setLocationValid(true);
                        setDeviceProfileLocationError('')
                      }
                    }}
                  />
                </View>
                <Text style={[styles.error_text]}>{DeviceProfileLocationError}</Text> */}
                {/* <View> */}
                {/* <Text style={[styles.error_text]}>{DeviceProfileNameError}</Text> */}
                {/* </View> */}
                <View style={isMobile ? { marginLeft: "-102%", marginTop: isAssignchecked === 1 ? "-120%" : "-120%", } : null}>
                  <Text style={isMobile ? [styles.lableText, { marginTop: -5 }] : styles.lableText} > Device Name</Text>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "95%",
                      borderWidth: 1,
                      borderRadius: 4,
                      padding: 10,
                      marginTop: 4,
                      marginBottom: -7,
                    } : styles.input}
                    placeholder="Enter Device Name"
                    value={DeviceProfileName}
                    onChangeText={(value) => {
                      setDeviceProfileNameValid(false);
                      setDeviceProfileName(value);
                      if (value.length == 0) {
                        setDeviceProfileNameError("Please enter Device name");
                        // console.log(" enter");
                      } else if (!alpha_pattern.test(value)) {
                        setDeviceProfileNameError(
                          "Please enter valid character only"
                        );
                        // console.log("error charcter");
                      } else {
                        setDeviceProfileNameValid(true);

                        setDeviceProfileNameError("");
                      }
                    }}
                  />

                  <Text style={[styles.error_text]}>
                    {DeviceProfileNameError}
                  </Text>
                </View>


                <View style={isMobile ? { marginLeft: "-100%", } : null}>
                  <Text style={isMobile ? {
                    color: "#484848",
                    fontWeight: "700",
                    marginTop: 1,
                    marginLeft: 1
                  } : styles.lableText}>Assignment Type </Text>
                  <View style={isMobile ? { flexDirection: "row", marginTop: 5 } : { flexDirection: "row" }}>
                    <Text style={[styles.statusNames, { color: "#05D6B3" }]}>
                      Zone
                    </Text>
                    <RadioButton
                      value={isAssignchecked}
                      status={isAssignchecked === 1 ? "checked" : "unchecked"}
                      onPress={() => setAssignChecked(1)}
                    />
                    <Text style={[styles.statusNames, { color: "#E11A1A" }]}>
                      SubZone
                    </Text>
                    <RadioButton
                      value={isAssignchecked}
                      status={isAssignchecked === 2 ? "checked" : "unchecked"}
                      onPress={() => setAssignChecked(2)}
                    />{" "}
                  </View>
                </View>

                {isAssignchecked === 2 && (
                  <View style={isMobile ? { marginLeft: "-100%", } : null}>
                    <Text style={isMobile ? [styles.lableText, { marginTop: '-1%' }] : styles.lableText}>Subzone </Text>
                    <View>
                      <Dropdown
                        ref={dropdownRefZone}
                        style={isMobile ? {
                          borderColor: "#c3c3c3",
                          width: "95%",
                          borderWidth: 1,
                          borderRadius: 2,
                          height: 40,
                          padding: 10,
                          marginTop: 5,
                          marginBottom: 5,
                        } : styles.dropdown}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={subzoneData}
                        search
                        maxHeight={300}
                        labelField="sub_zone_name"
                        valueField="id"
                        placeholder={
                          SubzoneValue ? SubzoneValue : "Select subzone "
                        }
                        searchPlaceholder="Search..."
                        value={SubzoneValue}
                        onChange={(item) => {
                          setSubzoneValue(item.sub_zone_name);
                          setSubzoneValueId(item.id);
                          setSubZoneIdError("");
                          // console.log("SUB zone id value---", item.id);
                        }}
                        renderItem={renderSubZoneIdListItems}
                      />
                      <Text style={[styles.error_text]}>{SubZoneIdError}</Text>
                    </View>
                  </View>
                )}
                <View style={isMobile ? { marginTop: "-19%", height: 0, marginLeft: -2 } : null}>
                  <Text style={isMobile ? [styles.lableText, { marginLeft: "-100%" }] : [styles.lableText]}> Device Code</Text>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "190%",
                      borderWidth: 1,
                      borderRadius: 4,
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                      backgroundColor: "#DEDEDE",
                      marginLeft: "-100%"
                    } : [styles.input, { backgroundColor: "#DEDEDE" }]}
                    placeholder="Enter Zone Number"
                    maxLength={10}
                    value={DeviceProfileCode}
                    editable={false}
                    keyboardType={"numeric"}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={isMobile ? {marginTop:isAssignchecked === 1 ? "40%" : "70%"} : null }>
            {DataRecord.length > 0 &&
              <View style={isMobile ? { marginTop: -20, width: "100%", alignSelf: "center" } : { marginTop: 20, width: "41%", alignSelf: "center" }}>
                <TabView
                  navigationState={{ index, routes }}
                  renderScene={renderScene}
                  onIndexChange={setIndex}
                  initialLayout={{ width: layout.width }}
                  // renderLabel={({ route, color }) => (
                  //   <Text style={{ color: 'black', margin: 8 }}>
                  //     {route.title}
                  //   </Text>
                  // )}
                  renderTabBar={(props) => (
                    <TabBar
                      {...props}
                      indicatorStyle={{ backgroundColor: "#2c7bbe", height: 50 }}
                      renderLabel={({ route, color }) => (
                        <Text style={isMobile ? { color: "Black", marginLeft: 10 } : { color: "Black", margin: 8 }}>
                          {route.title}
                        </Text>
                      )}
                      style={{ backgroundColor: "#F6F9F9" }}
                    />
                  )} // <-- add this line
                />
              </View>
            }
          </View>


          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "30%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => CheckEnteredData()}
            >
              <Text style={styles.btnText}>Update</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "30%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => navigation.navigate("DeviceProfile")}
            >
              <Text style={styles.btnText}>Cancel</Text>
            </TouchableOpacity>
          </View>

          {/* <View style={{ marginTop: 20 }}>
          <TabView
            navigationState={{ index, routes }}
            renderScene={renderScene}
            onIndexChange={setIndex}
            initialLayout={{ width: layout.width }}
          />
        </View> */}
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const customStyles = {
  rows: {
    style: {
      // maxWidth: 50,
      minHeight: "30px", // override the row height
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      backgroundColor: "#319ef0a3",
      minHeight: "40px",
      fontSize: 16,
      fontWeight: "600",
    },
  },
  headCells: {
    style: {
      // paddingLeft: '8px', // override the cell padding for head cells
      // paddingRight: '8px',
      justifyContent: "center",
      minHeight: "20px",

      // borderRightWidth: 2,
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        // fontSize: 16,
        // fontWeight: '600',
        // borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      justifyContent: "center",

      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        fontSize: 16,
        fontWeight: "400",
        // borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};
const columns = [
  // {
  //   name: "Sr.No",
  //   selector: "id",
  //   sortable: true
  // },
  {
    name: "Output number",
    // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
    selector: (row) => row.value,
    sortable: true,
  },
  {
    name: "Output Name",
    selector: (row) => row.name,
    sortable: true,
  },
  // {
  //   name: "Description", 
  //   selector: (row) => row.description != " " && row.description != null ? row.description : "-",
  //   sortable: true,
  // },
];
const columnsAnalog = [
  // {
  //   name: "Sr.No",
  //   selector: "id",
  //   sortable: true
  // },
  {
    name: "Number",
    // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
    selector: (row) => row.value,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
];

const columnDigitalInput = [
  // {
  //   name: "Sr.No",
  //   selector: "id",
  //   sortable: true
  // },
  {
    name: "Input number",
    selector: (row) => row.value,
    sortable: true,
  },
  {
    name: "Input Name",
    selector: (row) => row.name,
    sortable: true,
  },
];

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 150,
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  inputLocation: {
    borderColor: "#c3c3c3",
    width: "33%",
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 5,
    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  input: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 4,

    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "15%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,
    // marginVertical: 10,
    // paddingBottom: "48%",

    width: "100%",
    flex: 1,
    marginVertical: 0,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 17,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
});
